<template>
  <div class="col-md-12">
    <div class="card card-info card-outline ">
      <div class="card-header pt-2 pb-2">
        <h3 class="card-title">
          <i class="fa fa-signature text-navy"></i> Aprobaciones
        </h3>
      </div>
      <div class="card-body">
        <div class="row justify-content-md-center">
          <div class="col-md-4" v-for="firma in $parent.servicioInterno.firmas" :key="firma.id">
            <div class="card bg-light d-flex flex-fill">
              <div class="card-header text-muted border-bottom-0">
                <h5>{{ firma.nTipoFirma }}</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <p>Firmado por: <br />
                      <strong>{{ firma.user.name }}</strong>
                    </p>
                    <p class="text-center mr-5 ml-5">
                      <img class="img-fluid" :src="uri_docs + firma.user.link_firma"/>
                    </p>
                    <p>Fecha: <br />
                      <strong>{{ firma.fecha_firma }}</strong>
                    </p>
                    <p>Observación: <br />
                      {{ firma.observacion }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CsServicioDetalleFirmas",
  data() {
    return {
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    }
  },
};
</script>
